@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

:root {
  --primary-color: #2ca58d;
  --text-color: #004346;
  --background-color-1: #e1f9f3;
  --background-color-2: #e0e1e2;
  --box-shadow-1: 1px 3px 4px 0px #646468;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  top: 0 !important;
}

* {
  font-family: "Nunito", sans-serif;
}

.StripeElement {
  width: 100%;
}

.MuiBox-root {
  border-radius: 8px;
  padding: 30px;
  background-color: white;
}

.iLnKWj,
.jAkxYV {
  border: 0.5px solid #646464 !important;
}

@media (max-width: 75em) {
  html {
    font-size: 90% !important;
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 80% !important;
  }
}

@media (max-width: 37.5em) {
  html {
    font-size: 70% !important;
  }
}

@media (max-width: 25em) {
  html {
    font-size: 60% !important;
  }
}

@media (min-width: 112.5em) {
  html {
    font-size: 115% !important;
  }
}

#\:0\.container,
#\:1\.container,
#\:2\.container,
#goog-gt-tt,
#google_translate_element,
.goog-tooltip,
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
/* use em unit to ensure that style is adapted when use change the page default font size
--breakpoint-large-desktop: 112.5em;  1800px 
--breakpoint-desktop: 75em;  1200px 
--breakpoint-tablet: 56.25em;  900px 
--breakpoint-mobile: 37.5em;  600px 
--breakpoint-small-mobile: 25em;  400px */
